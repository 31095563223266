import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    brand: {
      50: "#eef3ff",
      100: "#d1deff",
      200: "#b3c9ff",
      300: "#96b3ff",
      400: "#789eff",
      500: "#4169E1",  // Royal Blue
      600: "#3a5ecc",
      700: "#3352b8",
      800: "#2c47a3",
      900: "#253b8f",
    },
  },
});

export default theme;
