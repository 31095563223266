import React from 'react';
import { Box, Heading, Button, Link, Text, UnorderedList, ListItem, VStack } from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';

function DocumentAnalyst() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h2" size="lg" mb={6}>
        General Document Analyzer
      </Heading>
      <Box mb={8}>
        <Link href="https://notebooklm.google.com/" isExternal target="_blank" rel="noopener noreferrer">
          <Button colorScheme="brand" size="lg" leftIcon={<FaExternalLinkAlt />}>
            Open Google NotebookLM
          </Button>
        </Link>
      </Box>
      <VStack spacing={6} align="stretch" textAlign="left">
        <Text>
          Google's NotebookLM is a powerful tool for analyzing large documents or collections of PDFs, such as justification books. Follow these steps to effectively use NotebookLM:
        </Text>
        <UnorderedList spacing={2}>
          <ListItem>
            <strong>Access NotebookLM:</strong> Click the button above to open NotebookLM in a new tab.
          </ListItem>
          <ListItem>
            <strong>Upload Documents:</strong> In NotebookLM, click on "Add source" and upload your PDF files or justification book.
          </ListItem>
          <ListItem>
            <strong>Create a New Notebook:</strong> Start a new notebook to begin your analysis.
          </ListItem>
          <ListItem>
            <strong>Ask Questions:</strong> Use the chat interface to ask specific questions about your documents. For example:
            <UnorderedList>
              <ListItem>"Summarize the main points of the justification book."</ListItem>
              <ListItem>"What are the key budget allocations mentioned in the document?"</ListItem>
              <ListItem>"Identify the primary objectives outlined in the first chapter."</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            <strong>Generate Summaries:</strong> Ask NotebookLM to create concise summaries of specific sections or the entire document.
          </ListItem>
          <ListItem>
            <strong>Analyze Trends:</strong> Request analysis of trends or patterns across the documents.
          </ListItem>
          <ListItem>
            <strong>Compare Sections:</strong> Ask NotebookLM to compare different parts of the document or multiple documents.
          </ListItem>
          <ListItem>
            <strong>Extract Key Information:</strong> Use queries to extract specific data points, statistics, or quotes.
          </ListItem>
          <ListItem>
            <strong>Save and Organize:</strong> Use NotebookLM's features to save important findings and organize your analysis.
          </ListItem>
        </UnorderedList>
        <Text>
          Remember, NotebookLM uses AI to assist in your analysis, but always verify important information against the original documents.
        </Text>
      </VStack>
    </Box>
  );
}

export default DocumentAnalyst;
