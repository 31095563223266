import React from 'react';
import { Box, Heading, Button, Link, Text, OrderedList, UnorderedList, ListItem, VStack } from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';

function HearingSummary() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h2" size="lg" mb={6}>
        Hearing Transcript Analyzer
      </Heading>
      <Box mb={8}>
        <Link href="https://chat.openai.com/g/g-8gozIdJo9-hearing-transcript-analyzer1-1" isExternal target="_blank" rel="noopener noreferrer">
          <Button colorScheme="brand" size="lg" leftIcon={<FaExternalLinkAlt />}>
            Open Hearing Summary Custom GPT
          </Button>
        </Link>
      </Box>
      <VStack spacing={6} align="stretch" textAlign="left">
        <Text>
          Follow these steps to analyze a hearing transcript using our custom GPT:
        </Text>
        <OrderedList spacing={4}>
          <ListItem>
            <strong>Download the Transcript:</strong>
            <Text mt={2}>Use the Transcript Downloader tool from the dashboard:</Text>
            <UnorderedList>
              <ListItem>Enter the YouTube URL of the hearing video.</ListItem>
              <ListItem>Provide a name for the hearing (optional).</ListItem>
              <ListItem>Click "Fetch Transcript" and then "Download Transcript".</ListItem>
              <ListItem>This will save a JSON file of the transcript to your device.</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            <strong>Access the Custom GPT:</strong>
            <Text mt={2}>Click the "Open Hearing Summary Custom GPT" button above to open the custom GPT in a new tab.</Text>
          </ListItem>
          <ListItem>
            <strong>Upload the Transcript:</strong>
            <Text mt={2}>In the chat interface with the custom GPT:</Text>
            <UnorderedList>
              <ListItem>Start a new message.</ListItem>
              <ListItem>Attach the JSON file you downloaded to this message.</ListItem>
              <ListItem>Add a brief instruction like "Please analyze this hearing transcript."</ListItem>
              <ListItem>Send the message.</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            <strong>Review the Summary:</strong>
            <Text mt={2}>The custom GPT will process the transcript and provide a structured summary. This may include:</Text>
            <UnorderedList>
              <ListItem>Key topics discussed</ListItem>
              <ListItem>Main arguments or points made</ListItem>
              <ListItem>Notable quotes</ListItem>
              <ListItem>Participants and their roles</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            <strong>Request Adjustments:</strong>
            <Text mt={2}>If you need changes or have specific requirements:</Text>
            <UnorderedList>
              <ListItem>Ask the GPT to focus on particular aspects of the hearing.</ListItem>
              <ListItem>Request a different format or structure for the summary.</ListItem>
              <ListItem>Ask for more details on specific topics or speakers.</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            <strong>Follow-up Questions:</strong>
            <Text mt={2}>Use the GPT to dive deeper into the transcript:</Text>
            <UnorderedList>
              <ListItem>Ask for clarification on specific points.</ListItem>
              <ListItem>Request comparisons between different speakers or topics.</ListItem>
              <ListItem>Inquire about the overall tone or sentiment of the hearing.</ListItem>
            </UnorderedList>
          </ListItem>
        </OrderedList>
        <Text>
          Remember, while the custom GPT is designed to provide accurate summaries, always verify crucial information against the original transcript or video when necessary.
        </Text>
      </VStack>
    </Box>
  );
}

export default HearingSummary;
