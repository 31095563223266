import React from 'react';
import { PageLayout } from './components/PageLayout';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import HearingSummary from './components/HearingSummary';
import TranscriptDownloader from './components/TranscriptDownloader';
import DocumentAnalyst from './components/DocumentAnalyst';

const App = () => {
  return (
    <PageLayout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/dashboard"
          element={
            <AuthenticatedTemplate>
              <Dashboard />
            </AuthenticatedTemplate>
          }
        />
        <Route
          path="/hearing-summary"
          element={
            <AuthenticatedTemplate>
              <HearingSummary />
            </AuthenticatedTemplate>
          }
        />
        <Route
          path="/download"
          element={
            <AuthenticatedTemplate>
              <TranscriptDownloader />
            </AuthenticatedTemplate>
          }
        />
        <Route
          path="/document-analyst"
          element={
            <AuthenticatedTemplate>
              <DocumentAnalyst />
            </AuthenticatedTemplate>
          }
        />
        <Route
          path="*"
          element={<Navigate to="/" />}
        />
      </Routes>
    </PageLayout>
  );
};

export default App;
