import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Input, Button, Heading, VStack, Text } from '@chakra-ui/react';

function TranscriptDownloader() {
  const [url, setUrl] = useState('');
  const [hearingName, setHearingName] = useState('');
  const [transcript, setTranscript] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setTranscript(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/transcript`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: url,
          hearingName: hearingName || 'transcript'
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch transcript');
      }

      const data = await response.json();
      setTranscript(data.transcript);
    } catch (error) {
      console.error('Error fetching transcript:', error);
      setError(`Error: ${error.message}`);
    }
  };

  const downloadTranscript = () => {
    if (transcript) {
      const element = document.createElement("a");
      const file = new Blob([JSON.stringify(transcript, null, 2)], { type: 'application/json' });
      element.href = URL.createObjectURL(file);
      element.download = `${hearingName || 'transcript'}.json`;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h2" size="lg" mb={6}>
        Download YouTube Transcript
      </Heading>
      <Box maxW="md" mx="auto">
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Enter YouTube URL</FormLabel>
              <Input
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter YouTube URL"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Hearing Name (optional)</FormLabel>
              <Input
                type="text"
                value={hearingName}
                onChange={(e) => setHearingName(e.target.value)}
                placeholder="Enter Hearing Name"
              />
            </FormControl>
            <Button colorScheme="brand" type="submit" width="full">
              Fetch Transcript
            </Button>
          </VStack>
        </form>
        {transcript && (
          <Box mt={6} textAlign="left">
            <Text>Transcript fetched successfully!</Text>
            <Button colorScheme="brand" mt={4} onClick={downloadTranscript}>
              Download Transcript
            </Button>
          </Box>
        )}
        {error && (
          <Text color="red.500" mt={4}>{error}</Text>
        )}
      </Box>
    </Box>
  );
}

export default TranscriptDownloader;
