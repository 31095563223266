import React from 'react';
import { Box, SimpleGrid, Heading, LinkBox, LinkOverlay, Image } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import hearingSummaryIcon from '../assets/hearingSummaryIcon.webp';
import transcriptDownloaderIcon from '../assets/transcriptDownloaderIcon.webp';
import documentAnalystIcon from '../assets/documentAnalystIcon.webp'; // Ensure you have this icon or update the path

function Dashboard() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h1" size="xl" mb={6}>
        Dashboard
      </Heading>
      <SimpleGrid columns={[1, null, 3]} spacing="40px">
        <LinkBox as="article" p="5" borderWidth="1px" rounded="md" _hover={{ bg: "brand.50" }}>
          <Image src={hearingSummaryIcon} alt="Hearing Summary Icon" w={10} h={10} mx="auto" />
          <Heading size="md" my="2">
            <LinkOverlay as={RouterLink} to="/hearing-summary">
              Hearing Transcript Analyzer
            </LinkOverlay>
          </Heading>
          <Box>
            Go to the CustomGPT Hearing Transcript Analyzer.
          </Box>
        </LinkBox>
        <LinkBox as="article" p="5" borderWidth="1px" rounded="md" _hover={{ bg: "brand.50" }}>
          <Image src={transcriptDownloaderIcon} alt="Transcript Downloader Icon" w={10} h={10} mx="auto" />
          <Heading size="md" my="2">
            <LinkOverlay as={RouterLink} to="/download">
              Transcript Downloader
            </LinkOverlay>
          </Heading>
          <Box>
            Download a transcript from a YouTube URL.
          </Box>
        </LinkBox>
        <LinkBox as="article" p="5" borderWidth="1px" rounded="md" _hover={{ bg: "brand.50" }}>
          <Image src={documentAnalystIcon} alt="Document Analyst Icon" w={10} h={10} mx="auto" />
          <Heading size="md" my="2">
            <LinkOverlay as={RouterLink} to="/document-analyst">
              Document Analyst
            </LinkOverlay>
          </Heading>
          <Box>
            Go to the Google NotebookLM Document Analyst.
          </Box>
        </LinkBox>
        {/* Add more tiles here as needed */}
      </SimpleGrid>
    </Box>
  );
}

export default Dashboard;
