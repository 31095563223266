import React from 'react';
import { Box, Heading, Flex } from '@chakra-ui/react';
import dcBackground from '../assets/dome.png';

const Home = () => {
  return (
    <Box
      backgroundImage={`url(${dcBackground})`}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        bg="rgba(255, 255, 255, 0.8)" 
        p={8} 
        borderRadius="md"
      >
        <Heading>Welcome to AdvocAide</Heading>
      </Flex>
    </Box>
  );
};

export default Home;
