import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { Box, Flex, Link, IconButton, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";

export const PageLayout = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      {/* Navbar */}
      <Flex
        as="nav"
        backgroundColor="brand.500"
        color="white"
        p={4}
        justifyContent="space-between"
        alignItems="center"
        height="60px"
        boxShadow="md"
        position="fixed"
        top="0"
        left="0"
        right="0"
        zIndex="10"
      >
        <Link 
          as={RouterLink} 
          to={isAuthenticated ? "/dashboard" : "/"}
          fontSize="xl"
          fontWeight="bold"
          textDecoration="none"
          color="white"
          _hover={{ textDecoration: 'none', color: "whiteAlpha.800" }}
        >
          AdvocAide
        </Link>

        <Box>
          {isAuthenticated ? (
            <Menu>
              <MenuButton as={IconButton} icon={<HamburgerIcon />} variant="outline" color="white" />
              <MenuList bg="brand.500">
                <MenuItem as={RouterLink} to="/hearing-summary" color="black" _hover={{ bg: "brand.600", color: "white" }}>
                  Hearing Transcript Analyzer
                </MenuItem>
                <MenuItem as={RouterLink} to="/download" color="black" _hover={{ bg: "brand.600", color: "white" }}>
                  Transcript Downloader
                </MenuItem>
                <MenuItem as={RouterLink} to="/document-analyst" color="black" _hover={{ bg: "brand.600", color: "white" }}>
                  Document Analyst
                </MenuItem>
                <MenuItem onClick={() => <SignOutButton />} color="black" _hover={{ bg: "brand.600", color: "white" }}>
                  Sign Out
                </MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <SignInButton />
          )}
        </Box>
      </Flex>

      {/* Content below the navbar */}
      <Box mt="60px" flexGrow="1">
        {children}
      </Box>
    </Box>
  );
};
