import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const SignInButton = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const handleLogin = () => {
    instance.loginPopup(loginRequest).then(() => {
      navigate('/dashboard'); // Redirect to dashboard after successful login
    }).catch((e) => {
      console.error(e);
    });
  };

  return (
    <Button colorScheme="brand" onClick={handleLogin}>
      Sign In
    </Button>
  );
};
