import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { BrowserRouter as Router } from 'react-router-dom';
import theme from './theme';  // Add this line to import the theme

console.log('Script is running');

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
if (!container) {
  console.error('Failed to find root element');
} else {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <Router>
            <App />
          </Router>
        </MsalProvider>
      </ChakraProvider>
    </React.StrictMode>
  );

  console.log('App rendered');
}
